.scroller__business {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  h2 {
    flex-basis: 8%;
    margin-top: 15px;
    text-align: center;
    text-transform: uppercase;
  }
  &_slider {
    width: 50%;
    flex-basis: 10%;
  }
  &_skills {
    flex-basis: 70%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    > div {
      flex-basis: 100%;
      h4 {
        width: 100%;
        border-bottom: 1px solid $scroller-text-color;
        margin-bottom: 5px;
        padding-left: 10px;
        text-align: justify;
        cursor: default;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        margin-left: 5px;
        li {
          flex: 1 1 33%;
          font-style: italic;
          font-weight: 500;
          text-indent: 10px;
          cursor: default;
        }
      }
    }
  }
  &_contact {
    width: 100%;
    flex-basis: 10%;
    h3 {
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      border-bottom: 1px solid $scroller-text-color;
    }
    ul {
      width: 90%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-around;
      li {
        flex-basis: 10%;
        margin: 7px 0 5px;
        color: $scroller-text-color;
        a {
          svg {
            max-width: clamp(2.5rem, 2.193rem + 1.754vw, 5rem);
          }
        }
      }
    }
  }
}

@media (min-width: $breakpoint-480) {
  .scroller__business {
    &_skills {
      > div {
        flex-basis: 80%;
      }
    }
    &_contact {
      ul {
        li {
          a {
            svg {
            }
          }
        }
      }
    }
  }
}
@media (min-width: $breakpoint-360) and (min-height: 800px) {
  .scroller__business {
    &_skills {
      flex-basis: 50%;
    }
  }
}

@media (max-width: $breakpoint-1024) and (min-height: $breakpoint-1024) {
  .scroller__business {
    h2 {
      flex-basis: 6%;
    }
    &_slider {
      flex-basis: 6%;
    }
    &_skills {
      flex-basis: 35%;
    }
  }
}

@media (min-width: $breakpoint-1400) {
  .scroller__business {
    h2 {
      margin-top: 25px;
      flex-basis: 10%;
    }
    &_slider {
      flex-basis: 10%;
    }
    &_skills {
      flex-basis: 60%;
    }
  }
}

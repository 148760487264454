.collectionButton {
  // overflow: hidden;
  width: 90%;
  margin: 10px auto 0;
  background-color: $fourth-color;
  font-size: $font-size-p;

  text-transform: uppercase;
  color: $apps_background;
  a,
  span {
    padding: 5px 0;
    text-align: center;
    font-weight: bold;
    letter-spacing: 1px;
  }
  span {
    display: block;
    width: 100%;
  }
}

@media (min-width: $breakpoint-700) {
  .collectionButton {
    a,
    span {
      padding: 10px 0;
    }
  }
}

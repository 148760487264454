.action_bar {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.4;
  transition: 0.3s;

  &__button {
    padding: 7px;
    font-size: $font-size-basic;
    cursor: pointer;
  }
}

.action_bar:hover {
  opacity: 1;
}

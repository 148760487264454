.bookCollection {
  margin: 10px auto;
  // height: 100%;
  .list {
    border: none;

    &__tiles {
      margin: 10px auto;
      &__single_tile {
        margin-top: 5px;
      }
    }
  }
  a.router_link {
    color: $fourth-color;
    text-decoration: none;
  }
  &__welcome {
    width: 90%;
    margin: 0 auto;
    font-size: $font-size-p;
  }
  &__admin {
    &_navigation {
      // padding-bottom: 10px;
      // border-bottom: 1px solid;
    }
  }
}
.invalid {
  border: red 1px solid;
}

// @media (min-width)

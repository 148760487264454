.scroller__projects {
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  h2 {
    width: 100%;
    margin: 10px 0;
    text-align: center;
    text-transform: uppercase;
  }
  &_container {
    margin-bottom: 15px;
    h3 {
      margin-bottom: 5px;
      text-align: center;
      text-transform: uppercase;
    }
    div {
      width: 50%;
      margin: 0 auto;
      a {
        cursor: none;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    p {
      width: 90%;
      text-align: justify;
      margin: 5px auto 5px;
    }
  }
}

@media (min-width: $breakpoint-480) {
  .scroller__projects {
    &_container {
      div {
        width: 70%;
      }
    }
  }
}
@media (min-width: $breakpoint-700) {
  .scroller__projects {
    h2 {
      margin-top: 15px;
    }
    &_container {
      h3 {
        margin-bottom: 0;
      }
      div {
        margin-bottom: 10px;
      }
    }
  }
}
@media (min-width: $breakpoint-1024) {
  .scroller__projects {
    h2 {
      margin-top: 25px;
    }
    &_container {
      width: 95%;
      margin: 0 auto 25px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-evenly;

      h3 {
        flex-basis: 100%;
        margin-bottom: 10px;
      }
      div {
        flex-basis: 40%;
        margin: 0;
      }
      p {
        flex-basis: 45%;
        margin: 0;
      }
    }
  }
}
// @media (orientation: landscape) and (min-width: 800px) {
//   .scroller__projects {
//     justify-content: space-evenly;
//     flex-direction: row;
//     flex-wrap: wrap;
//     h2 {
//       flex-basis: 90%;
//       margin-top: 20px;
//     }
//     &_container {
//       height: 80vh;
//       flex-basis: 45%;

//       h3 {
//         text-align: center;
//         text-transform: uppercase;

//         padding: 5px 5px;
//         letter-spacing: 0.5px;
//       }
//       div {
//         width: 90%;
//       }
//       p {
//         width: 90%;
//       }
//     }
//   }
// }

.scroller__homepage {
  height: 100vh;
  background-image: url('../../../public/dreams-2904682.jpg');
  background-size: cover;
  background-position: center;
  overflow: hidden;
  &_title {
    position: relative;
    top: 15vh;
    // width: 80%;
    margin: 0 auto;
    padding: 20px 10px;
    text-align: center;
    color: $third-color;
    background-color: rgba(16, 13, 13, 0.3);

    &_h1 {
      line-height: calc($font-size-h1 * 1.25);
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
    &_h3 {
      margin-top: 3px;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }
}
// @media (orientation: landscape) and (max-height: 400px) {
// }

// @media (min-width: 540px) and (min-height: 720px) {
//   .scroller__homepage {
//     &_title {
//     }
//   }
// }

// @media (orientation: landscape) and (min-width: 800px) {
//   .scroller__homepage {
//     &_title {
//       &_h1 {
//       }
//       &_h2 {
//       }
//     }
//   }
// }
// @media (min-width: $breakpoint-1025) {
//   .scroller__homepage {
//     &_title {
//     }
//   }
// }

// @media (min-width: $breakpoint-1025) {
//   .scroller__homepage {
//     &_title {
//       &_h1 {
//       }
//       &_h2 {
//       }
//     }
//   }
// }

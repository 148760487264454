.bookCollection {
  max-width: 1400px;
  &__books {
    &__list.bookCollection__list {
      width: 95%;
      .bookCollection__list_element {
        flex-basis: 95%;
        a {
          .bookCollection__list_element_thumbnail {
            // min-width: 20px;
            min-width: $book_thumbnail;
            width: $book_thumbnail;
            height: auto;
            margin-right: 10px;
          }
        }
      }
    }
    &__filter {
      &_hideButton {
        width: 90%;
        margin: 5px auto 5px;
        font-weight: bold;
        letter-spacing: 1px;
      }
      &_showButton {
        width: 90%;
        margin: 0 auto;
        font-weight: bold;
        letter-spacing: 1px;
      }
      &_filters {
        width: 90%;
        margin: 0 auto;
        > div:nth-last-child(1) {
          width: 100%;
          margin: 10px 0;
        }
      }
    }
  }
}

@media (min-width: $breakpoint-1024) {
  .bookCollection {
    &__books {
      width: 95%;
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      justify-content: space-between;
      &__filter {
        flex-basis: 25%;
        &_filters {
          width: 100%;
        }
      }
      &__list {
        flex-basis: 70%;
      }
    }
  }
}

// @media (min-width: $breakpoint-1201) {
//   .bookCollection {
//     &__books {
//       &__filter {
//         flex-basis: 30%;
//       }
//       &__list {
//         flex-basis: 70%;
//       }
//     }
//   }
// }
// @media (min-width: $breakpoint-1400) {
//   .bookCollection {
//     &__books {
//       &__filter {
//       }
//       &__list {
//       }
//     }
//   }
// }

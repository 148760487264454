.editor_wrapper {
  position: relative;
  height: 100%;
  width: calc(100% - 10px);
  &__button {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 20;
    opacity: 0;
    transition: opacity 0.3s;
    letter-spacing: 0.5px;
    padding: 5px;
  }
  &:hover {
    button {
      opacity: 1;
    }
  }
}

.form-control-radio {
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.75em;
  font-size: $font-size-basic;
  text-transform: capitalize;
  // font-weight: bold;
  line-height: 1.1;
  & + .form-control-radio {
    // possible spacing
  }
}

input[type='radio'] {
  display: grid;
  // grid-template-columns: 1em;
  appearance: none;
  background-color: #fff;
  margin: 0;
  padding: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  // border: 0.15em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);
  place-content: center;
  &::before {
    display: inline;
    content: '';
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em $apps_background;
    background-color: CanvasText;
  }
  &:checked::before {
    transform: scale(1);
  }
  &:focus {
    outline: max(2px, 0.15em) solid currentColor;
    outline-offset: max(2px, 0.15em);
  }
}

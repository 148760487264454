html {
  font-size: $font-size-root;
  font-family: $font-basic;
}
body {
  background-color: $apps_background;
  #root {
    height: 100dvh;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  #apps {
    flex-basis: 92%;
    background-color: $apps_background;
    color: $fourth-color;
  }
  #scroller {
    background: rgb(232, 224, 175);
    background: radial-gradient(circle, rgba(232, 224, 175, 1) 49%, rgba(254, 252, 236, 1) 84%);
    // background-color: $scroller-background-color;
    color: $scroller-text-color;
    overflow: hidden;
  }
  button {
    font-family: $font-basic;
    font-size: 50px;
  }
  input {
    font-family: $font-basic;
  }
  a {
    // -webkit-tap-highlight-color: transparent; might be needed
    line-height: 1.25;
    cursor: none;
  }

  h2,
  h3,
  h4,
  h5 {
    line-height: 1.25;
    font-family: $font-headings;
    letter-spacing: 0.5px;
    cursor: default;
    font-weight: 500;
  }
  h1 {
    font-family: $font-front-heading;
    font-size: $font-size-h1;
    cursor: default;
  }
  h2 {
    font-size: $font-size-h2;
  }
  h3 {
    font-size: $font-size-h3;
  }
  h4 {
    font-size: $font-size-h4;
  }
  h5 {
    font-size: $font-size-h5;
  }
  p {
    font-size: $font-size-p;
    line-height: 1.25;
  }
  li {
    cursor: default;
  }
  li,
  label {
    font-size: $font-size-p;
    line-height: 1.25;
    letter-spacing: 0.2px;
  }
}

@media (hover: hover) {
  body {
    div.collectionButton {
      cursor: pointer;
    }
    a,
    img,
    button,
    span {
      cursor: pointer;
    }
  }
}

.bookCollection__search {
  --search_arrow_size: 6px;
  --search_height: 15px;

  margin: 15px auto 15px;
  width: 90%;

  &__searchBox {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    height: var(--search_height);
    display: flex;
    justify-content: center;
    padding: 8px 0;
    box-shadow: 0 8px 6px -10px #b3c6ff;
    &_dropdown {
      flex-basis: 40%;
      border-right: 2px solid #dde2f1;
      letter-spacing: 1px;
      color: $apps_background;
      text-transform: capitalize;
      position: relative;
      font-size: $font-size-small;
      &_default {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-transform: uppercase;
        font-size: $font-size-p;
      }
      ul {
        display: none;
        z-index: 1;
        position: absolute;
        top: 25px;
        left: -10px;

        width: 70%;

        padding: 10px 20px 10px 10px;
        background: #fff;
        border-radius: 5px;
        box-shadow: 8px 8px 6px -10px #b3c6ff;

        &.active {
          display: block;
        }
        li {
          padding-bottom: 20px;
          &:last-child {
            padding-bottom: 0;
          }
          // &:hover {
          //   color: #6f768d;
          // } move for desktop
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: calc(50% - (var(--search_arrow_size) / 3));
        right: 10%;
        border: var(--search_arrow_size) solid;
        border-color: #5078ef transparent transparent transparent;
      }
    }
    &_searchField {
      position: relative;
      flex-basis: 50%;
      height: 100%;
      &_input {
        height: 100%;
        outline: none;
        width: 80%;
        padding: 0 0 0 20px;
        border: none;
        font-size: $font-size-p;
        color: $apps_background;
      }
      .fas {
        position: absolute;
        top: 25%;
        right: $font-size-p;
        font-size: $font-size-small;
        color: #5078ef;
      }
      &_results {
        width: 70%;
        display: none;
        position: absolute;
        top: 25px;
        left: 10px;
        background: #fff;
        border-radius: 5px;
        padding: 20px;
        box-shadow: 8px 8px 6px -10px #b3c6ff;
        color: $apps_background;
        font-size: $font-size-p;
        &.active {
          display: block;
          z-index: 1;
        }

        &_item {
          padding-bottom: 20px;
          &:last-child {
            padding-bottom: 0;
          }
          &:hover {
            color: #6f768d;
          }
        }
      }
    }
  }
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #9fa3b1;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #9fa3b1;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #9fa3b1;
}

@media (min-width: $breakpoint-360) {
  .bookCollection__search {
    --search_height: 20px;
    &__searchBox {
      &_dropdown {
        flex-basis: 30%;
        ul {
          top: 30px;
          left: -5px;
        }
      }
      &_searchField {
        flex-basis: 60%;

        &_results {
          width: 80%;
          top: 30px;
          left: 5px;
        }
      }
    }
  }
}
@media (min-width: $breakpoint-1024) {
  .bookCollection__search {
    &__searchBox {
      &_dropdown {
        flex-basis: 20%;
      }
      &_searchField {
        flex-basis: 70%;
      }
    }
  }
}

.cell-list {
  margin: 0 15px 20vh;
  button {
    background-color: $cp_button_background;
    border: none;
    color: white;
    font-size: $font-size-p;
    cursor: pointer;
  }
}

.react-draggable-transparent-selection .cell-list {
  margin-bottom: 100vh;
}

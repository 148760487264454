.userBookDetails {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  h5 {
    margin: 0 auto 10px;
    font-size: $font-size-h5;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  > div {
    flex-basis: 90%;
    margin: 0 auto 10px;
    > i,
    p {
      text-align: center;
      font-size: $font-size-p;
    }
  }
  &_status {
    p {
      font-size: $font-size-p;
    }
  }
  &_rating {
    &-wrapper {
      width: 50%;
      margin: 0 auto;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      i {
        font-size: $font-size-p;
      }
    }
  }
  &_purchased {
    > div {
      > p:nth-child(1) {
        width: 40%;
        margin: 5px auto 3px;

        letter-spacing: 0.5px;
        border-bottom: 1px solid;
      }
      div {
        &:nth-last-child(1) {
          margin-bottom: 5px;
        }
      }
    }
  }
}

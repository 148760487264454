footer.footer {
  flex-basis: 4%;
  position: relative;
  bottom: 0;
  left: 0;

  div.footer__wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid $fourth-color;
    color: $fourth-color;
    justify-content: center;
    align-items: center;

    span {
      flex-basis: 100%;
      display: block;
      margin-top: 4px;
      font-size: $font-size-p;
      letter-spacing: 1.75px;
      text-align: center;
    }
    a {
      margin-bottom: 2px;
      font-size: $font-size-small;
      text-align: center;
    }
  }
  div.languageSelect {
    width: 100%;
    select {
      display: block;
      margin: 0 auto;
    }
  }
}
@media (min-width: $breakpoint-480) {
  footer.footer {
    div.languageSelect {
      width: auto;
      position: absolute;
      top: 25%;
      right: 2%;
    }
  }
}

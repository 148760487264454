.form-control {
  --form-control-disabled: #959495;
  font-size: $font-size-small;
  line-height: 1.2;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;

  &--disabled {
    color: var(--form-control-disabled);
    cursor: not-allowed;
  }
}

input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  // background-color: #fff;
  margin: 0;
  font-style: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.1em solid currentColor;
  border-radius: 0.15em;
  // transform: translateY(-0.075em);

  display: grid;
  place-content: center;
  &::before {
    content: '';
    background-color: CanvasText;
    width: 1em;
    height: 1em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em $fourth-color;
    transform-origin: bottom left;
    clip-path: polygon(15% 39%, 40% 76%, 87% 17%, 42% 51%);
  }
  &:checked::before {
    transform: scale(1);
  }
  // &:focus {
  //   outline: max(2px, 0.15em) solid currentColor;
  //   outline-offset: max(2px, 0.15em);
  // }
  &:disabled {
    --form-control-color: var(--form-control-disabled);

    color: var(--form-control-disabled);
    cursor: not-allowed;
  }
}

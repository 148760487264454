// @mixin noArrowsNumberInput {
//   input {
//     &::-webkit-outer-spin-button,
//     &::-webkit-inner-spin-button {
//       -webkit-appearance: none;
//       margin: 0;
//     }
//     &[type='number'] {
//       -moz-appearance: textfield;
//     }
//   }
// }

.bookCollection {
  &__addBook {
    width: 95%;
    margin: 0 auto;
    &__bookForm {
      &__form {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .addBookForm_element {
          // flex-basis: 100%;
          width: 100%;
          display: flex;
          margin: 2px 0;
          // flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          > label {
            font-size: $font-size-p;
            flex-basis: 35%;
            &::first-letter {
              text-transform: capitalize;
            }
          }
          > input {
            min-width: 0;
            flex-grow: 1;
            font-size: $font-size-p;
            // width: 150px;
            &[type='radio'] {
              width: auto;
            }
          }
          > select {
            min-width: 0;
            font-family: $font-basic;
            font-size: $font-size-p;
            // flex-basis: 65%;

            text-align: center;
          }

          &_genres,
          &_authors,
          &_translators,
          &_bookSeries {
            flex-wrap: wrap;
            .select_wrapper {
              label {
                flex-basis: 30%;
              }
              &-inner {
                min-width: 0;
                flex-basis: 70%;
                select {
                }
                button {
                  &.select_remove-button {
                  }
                }
              }
            }
          }
          &_cover-upload {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            label {
              font-size: $font-size-p;
              flex-basis: 50%;
            }
            &__fileInput {
              flex-basis: 50%;
              input {
                font-size: $font-size-p;
                min-width: 0;
                width: 100%;
              }
              &_preview {
                flex-basis: 100%;
                margin: 10px auto;
                max-width: 75px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
          &_isBookSeries {
            margin-bottom: 5px;
            label:nth-child(1) {
              flex-basis: 60%;
            }
            label {
              flex-basis: 15%;
            }
          }
          + .addBookForm_element {
            margin-top: 2px;
          }
          &_cover:nth-child(1) {
            margin: 0 auto 20px;
            max-width: 200px;

            img {
              width: 100%;
            }
          }
        }
      }
    }
    &__options {
      width: 70%;
      margin: 20px auto;

      &_button {
      }
    }
    &__upload {
      margin-top: 20px;
      p {
        text-align: justify;
      }
      &_file-details {
        font-size: $font-size-p;
        margin-bottom: 20px;
      }
      form {
        margin-top: 20px;
      }
      &_missing {
        > p {
          text-transform: uppercase;
          text-align: center;
          line-height: 110%;
        }
        > div {
          > p {
            text-transform: uppercase;
            margin-top: 10px;
            padding-bottom: 3px;
            border-bottom: 1px solid $fourth-color;
          }
        }
      }
      &_submit {
        &.collection_button {
        }
      }
      &_uploader {
      }
    }
  }
}

// .add_book {
//   .upload {
//   }
//   .add_book__form {
//     &_element {
//       &__pages {
//         @include noArrowsNumberInput();
//       }
//       &__firstEdition {
//         @include noArrowsNumberInput();
//       }
//     }
//   }
// }
@media (min-width: $breakpoint-360) {
  .bookCollection {
    &__addBook {
      &__bookForm {
        &__form {
          .addBookForm_element {
            label {
              // flex-basis: 28%;
            }

            input {
            }
            > select {
            }
            &_genres,
            &_authors,
            &_translators,
            &_bookSeries {
              .select_wrapper {
                label {
                }
                &-inner {
                  select {
                  }
                  button {
                    &.select_remove-button {
                    }
                  }
                }
              }
            }
            &_cover-upload {
              label {
                flex-basis: 30%;
              }
              &__fileInput {
                flex-basis: 70%;
              }
              input {
              }
            }
            + .addBookForm_element {
            }
            &_cover:nth-child(1) {
              img {
              }
            }
            &_isBookSeries {
              label:nth-child(1) {
              }
              label {
              }
            }
          }
        }
      }
    }
    &__options {
      &_button {
      }
    }
    &__upload {
      p {
      }
      &_file-details {
      }
      form {
      }
      &_missing {
        > p {
        }
        > div {
          > p {
          }
        }
      }
      &_submit {
        &.collection_button {
        }
      }
      &_uploader {
      }
    }
  }
}
@media (min-width: $breakpoint-480) {
  .bookCollection {
    &__addBook {
      width: 90%;
      &__bookForm {
        &__form {
          .addBookForm_element {
            label {
              // flex-basis: 28%;
            }

            input {
            }
            > select {
            }
            &_genres,
            &_authors,
            &_translators,
            &_bookSeries {
              .select_wrapper {
                label {
                }
                &-inner {
                  select {
                  }
                  button {
                    &.select_remove-button {
                    }
                  }
                }
              }
            }
            &_cover-upload {
              label {
              }
              &__fileInput {
                &_preview {
                  max-width: 100px;
                }
              }
              input {
              }
            }
            + .addBookForm_element {
            }
            &_cover:nth-child(1) {
              img {
              }
            }
            &_isBookSeries {
              label:nth-child(1) {
              }
              label {
              }
            }
          }
        }
      }
    }
    &__options {
      &_button {
      }
    }
    &__upload {
      p {
      }
      &_file-details {
      }
      form {
      }
      &_missing {
        > p {
        }
        > div {
          > p {
          }
        }
      }
      &_submit {
        &.collection_button {
        }
      }
      &_uploader {
      }
    }
  }
}

@media (min-width: $breakpoint-700) {
  .bookCollection {
    &__addBook {
      &__bookForm {
        &__form {
          justify-content: space-between;
          .addBookForm_element {
            &_cover:nth-child(1) {
              flex-basis: 100% !important;
            }

            // &:nth-child(-n + 3) {
            //   flex-basis: 100%;
            // }

            // &:nth-child(n + 10) {
            //   flex-basis: 100%;
            // }

            label {
              flex-basis: 30%;
            }

            input {
              flex-grow: 1;
            }
            > select {
              // flex-basis: 70%;
            }
            &_genres,
            &_authors,
            &_translators,
            &_bookSeries {
              .select_wrapper {
                label {
                }
                &-inner {
                  select {
                  }
                  button {
                    &.select_remove-button {
                    }
                  }
                }
              }
            }
            &_cover-upload {
              label {
                flex-basis: 20%;
              }
              &__fileInput {
                &_preview {
                  max-width: 150px;
                }
              }
              input {
              }
            }
            + .addBookForm_element {
            }
            &_cover:nth-child(1) {
              img {
              }
            }
            &_isBookSeries {
              label:nth-child(1) {
              }
              label {
              }
            }
          }
        }
      }
    }
    &__options {
      &_button {
      }
    }
    &__upload {
      p {
      }
      &_file-details {
      }
      form {
      }
      &_missing {
        > p {
        }
        > div {
          > p {
          }
        }
      }
      &_submit {
        &.collection_button {
        }
      }
      &_uploader {
      }
    }
  }
}

@media (min-width: $breakpoint-1201) {
  .bookCollection {
    &__addBook {
      &__bookForm {
        &__form {
          .addBookForm_element {
            &_cover:nth-child(1) {
            }

            &:nth-child(-n + 9) {
            }
            label {
              flex-basis: 25%;
            }

            input {
              flex-grow: 1;
            }
            > select {
            }
            &_genres,
            &_authors,
            &_translators,
            &_bookSeries {
              .select_wrapper {
                justify-content: space-between;

                label {
                  flex-basis: 25%;
                }
                &-inner {
                  flex-basis: 75%;
                  select {
                    flex-basis: 50%;
                  }
                  button {
                    flex-basis: 27%;
                    &.select_remove-button {
                      flex-basis: 7%;
                    }
                  }
                }
              }
            }
            &_cover-upload {
              label {
              }
              &__fileInput {
                &_preview {
                }
              }
              input {
              }
            }
            + .addBookForm_element {
            }
            &_cover:nth-child(1) {
              img {
              }
            }
            &_isBookSeries.addBookForm_element {
              margin: 15px 0;
              label:nth-child(1) {
              }
              label {
              }
            }
          }
        }
      }
    }
    &__options {
      &_button {
      }
    }
    &__upload {
      p {
      }
      &_file-details {
      }
      form {
      }
      &_missing {
        > p {
        }
        > div {
          > p {
          }
        }
      }
      &_submit {
        &.collection_button {
        }
      }
      &_uploader {
      }
    }
  }
}
@media (min-width: $breakpoint-1400) {
  .bookCollection {
    &__addBook {
      &__bookForm {
        &__form {
          .addBookForm_element {
            &_cover:nth-child(1) {
            }

            &:nth-child(-n + 9) {
            }
            label {
              // flex-basis: 28%;
            }

            input {
            }
            > select {
            }
            &_genres,
            &_authors,
            &_translators,
            &_bookSeries {
              .select_wrapper {
                flex-basis: 49%;

                label {
                }
                &-inner {
                  select {
                  }
                  button {
                    flex-basis: 35%;
                    &.select_remove-button {
                    }
                  }
                }
              }
            }
            &_cover-upload {
              label {
              }
              &__fileInput {
                &_preview {
                }
              }
              input {
              }
            }
            + .addBookForm_element {
            }
            &_cover:nth-child(1) {
              img {
              }
            }
            &_isBookSeries {
              label:nth-child(1) {
              }
              label {
              }
            }
          }
        }
      }
    }
    &__options {
      &_button {
      }
    }
    &__upload {
      p {
      }
      &_file-details {
      }
      form {
      }
      &_missing {
        > p {
        }
        > div {
          > p {
          }
        }
      }
      &_submit {
        &.collection_button {
        }
      }
      &_uploader {
      }
    }
  }
}
